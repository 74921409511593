/* istanbul ignore file */

/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
/**
 * Polyfill stable language features. These imports will be optimized by `@babel/preset-env`.
 *
 * See: https://github.com/zloirock/core-js#babel
 */
 import 'core-js/stable'
 // eslint-disable-next-line import/no-extraneous-dependencies
 import 'regenerator-runtime/runtime'
 
 (window as any).global = window;
 global.Buffer = global.Buffer || require('buffer').Buffer;
 